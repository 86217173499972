import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Leistungen" />
    <section className="page">
      <h1 className="text_huge">Leistungen</h1>
      <article>
        <h2 className="text_bigger">

        </h2>
        <ul>
          <li>Wir bieten <span className="bold">über 20 Jahre Erfahrung in der Entwicklung von Webseiten und Webapplikationen</span>.</li>
          <li>Wir arbeiten bevorzugt mit <span className="bold">WordPress/TYPO3/Frameworks</span>.</li>
          <li>Wir übernehmen die <span className="bold">komplette Projektabwicklung</span>.</li>
          <li>Wir sind <span className="bold">kompetente Partner</span>.</li>
          <li>Wir sind <span className="bold">Experten in verschiedensten Technologien</span>.</li>
          <li>Wir haben <span className="bold">Interesse an langfristiger Zusammenarbeit mit Kunden/Mitarbeitern/Partnern</span>.</li>
          <li>Wir betreuen Kunden <span className="bold"> vom Kleinbetrieb bis zu Großfirmen und Institution</span>.</li>
          <li>Wir setzen  <span className="bold">große und kleine Projekte um</span>.</li>
        </ul>
        <p>
          Webseiten und Webapplikationen entwickeln wir für unsere Kunden seit 1999. Wir sind Experten in verschiedensten Technologien. Für Standardwebseiten verwenden wir großteils WordPress und TYPO3.
          Individuelle Websoftwarelösungen entwickeln wir mit fertigen Frameworks.<br /><br />

          Unsere Kunden begleiten wir in allen Projektphasen. Wir bieten alles aus einer Hand, von der Beratung und Projektevaluierung über die Konzeption und Gestaltung bis zur Umsetzung und laufenden
          Betreuung.<br /><br />

          Unsere Kernkompetenzen sind die Konzeption und die technische Umsetzung.<br /><br />

          Mit kompetenten Partnern realisieren wir, was wir selber nicht in passender Qualität anbieten können. Langfristige und gute Zusammenarbeit ist uns wichtig. Egal ob mit Kunden, Mitarbeitern oder Partnern.
          <br /><br />

          Unsere Referenzen sprechen für uns. Unsere Kunden reichen vom kleinen Geschäft bis zu Großbetrieben und Institutionen. Unsere Projekte variieren vom kleinen Internetauftritt bis zu Portalseiten/Websoftware.
        </p>
      </article>
    </section>
  </Layout>
)

export default IndexPage
